.app {
  box-shadow: 0 0 50px inset #fff;
  font-family: sans-serif;
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.content {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  align-items: center;
  justify-content: center;
}

.row {
  width: auto;
}

.gradient-overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 3;
  background: radial-gradient(
    circle at center right,
    rgba(252, 182, 124, 0.5) 0%,
    rgba(56, 12, 1, 0.5) 100%
  );
}

.blur {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 2;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  pointer-events: none;
}

.vignette {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: 0.75;
  background: radial-gradient(circle, transparent 40%, rgb(0, 0, 0) 100%);
}
